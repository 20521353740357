h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}

h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

button,
a {
  &.green {
    color: #00a651;
  }

  &.red {
    color: #ed6e5d;
  }

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.tag {
  border-radius: 5px;
  font-size: 11px;
  line-height: 10px;
  padding: 4px 6px;
  display: inline-block;

  &.small {
    font-size: 9px;
  }

  &.blue {
    background: #b0ecf4;
  }

  &.red {
    background: #ed6e5d;
    color: white;
  }

  &.green {
    background: #00a651;
    color: white;
  }

  &.blue-light {
    background: #e2e6ef;
  }

  &.dark-blue {
    background: #7ccdd8;
    color: white;
  }

  &.dark-purple {
    background: #9799ff;
    color: white;
  }

  &.orange {
    background: #ffcf97;
  }

  &.square {
    border-radius: 2px;
  }
}

// *:not(input, textarea, select) {
//   user-select: none;
// }

.ant-dropdown {
  .ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
    padding: 10px 0;
    min-width: 200px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);

    >.ant-menu-item {
      height: 20px;
      line-height: 20px;
    }
  }
}

.dual-page {
  display: flex;
  overflow: hidden;

  >.left {
    flex: 1;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 45px 30px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
    min-height: 100vh;

    img {
      width: 250px;
      height: 250px;
      margin-bottom: 30px;
      position: relative;
    }

    h2 {
      font-weight: bold;
      font-size: 40px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      position: relative;
      max-width: 500px;
      width: 100%;
    }

    i {
      color: white;
      position: relative;
      font-size: 16px;
    }

    &:before {
      content: '';
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(8, 69, 38, 0.3);
    }
  }

  >.right {
    flex: 1;
    background: white;
    max-width: 744px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    overflow: auto;
    padding: 25px;

    >form {
      max-width: 500px;
      width: 100%;

      a {
        font-size: 12px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #00a651;
      }

      .shyfter-logo {
        width: 210px;
        display: block;
        margin: 0 auto;
      }

      h1 {
        font-weight: 700;
        font-size: 24px;
        padding-bottom: 10px;
      }

      p {
        font-size: 16px;
        color: #7f8894;
      }

      .fields {
        margin-top: 25px;
        position: relative;

        .ant-row.ant-form-item {
          margin-bottom: 25px;
        }

        label {
          font-weight: bold;
          font-size: 18px;
          line-height: 14px;
        }
      }

      button[type='submit'] {
        width: 100%;
        font-size: 18px;
        line-height: 26px;
      }

      .generatedBy {
        display: block;
        font-size: 11px;
        line-height: 24px;
        text-align: center;
      }
    }
  }

  @media (max-width: 800px) {
    min-height: auto;
    max-height: none;
    height: auto;
    flex-direction: column;
    overflow: auto;

    .left {
      h2 {
        font-size: 28px;
        line-height: 38px;
      }
    }

    .right {
      max-height: none;
    }
  }
}

@media screen and (max-width: 800px) {
  .dual-page {
    flex-direction: column;

    >.left {
      display: none;
    }

    >.right {
      align-items: center;
    }
  }

  .card-shadow {
    padding: 0
  }
}

.ant-table-wrapper {
  overflow: auto;
}

#root {
  white-space: pre-line;
}